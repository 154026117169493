import { useTOTPValidation } from "hooks/useTOTPValidation";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const TEST_MODE_CODE = 872;

interface VerifyCodeDependencies {
  useGetLocker: () => { data?: Locker };
  useOpenDoor: () => {
    trigger: (position: string) => Promise<void>;
  };
  useReservationCache: () => { data: any[] };
  useRebootSystem: () => { reboot: () => Promise<void> };
}

interface VerifyCodeProps {
  totpSecrets: TOTPSecret[] | undefined;
  setTriggerReservationQuery: (code: number) => void;
}

export const useVerifyCode = (
  { totpSecrets, setTriggerReservationQuery }: VerifyCodeProps,
  {
    useGetLocker,
    useOpenDoor,
    useReservationCache,
    useRebootSystem,
  }: VerifyCodeDependencies
) => {
  const navigate = useNavigate();
  const locker = useGetLocker();
  const openDoor = useOpenDoor();
  const reservationCache = useReservationCache();
  const { validate, validateDoorTOTP } = useTOTPValidation(
    totpSecrets as TOTPSecret[]
  );
  const rebootSystem = useRebootSystem();

  return useCallback(
    async (inputCode: string) => {
      const numericCode = parseInt(inputCode, 10);
      const door = locker.data?.doors?.find(
        (d) => d.unlock_code === numericCode
      );

      if (door) {
        await openDoor.trigger(door.position);
        navigate("code-success");
        return;
      }

      if (locker.data?.test && numericCode === TEST_MODE_CODE) {
        navigate("test");
        return;
      }

      // TOTP verification
      if (totpSecrets) {
        const validatedSecret = validate(inputCode);
        if (validatedSecret) {
          switch (validatedSecret.name) {
            case "reload_window":
              window.location.reload();
              return;
            case "reboot_system":
              await rebootSystem.reboot();
              return;
            case "test_page":
              navigate("test");
              return;
          }
        }

        // Door TOTP verification
        const doorTOTPSecret = totpSecrets?.find((s) => s.name === "door_totp");
        if (doorTOTPSecret && locker.data?.doors?.length) {
          // Run all door validations in parallel
          const validations = await Promise.allSettled(
            locker.data.doors.map((door) =>
              validateDoorTOTP(inputCode, doorTOTPSecret, door.position).then(
                (isValid) => ({ isValid, position: door.position })
              )
            )
          );

          // Start of Selection
          const validDoor = validations.find(
            (
              result
            ): result is PromiseFulfilledResult<{
              isValid: boolean;
              position: string;
            }> => result.status === "fulfilled" && result.value.isValid
          )?.value;
          if (validDoor) {
            await openDoor.trigger(validDoor.position);
            navigate("code-success");
            return;
          }
        }
      }

      // Check reservation cache
      const cachedReservation = reservationCache.data.find(
        (r) => r.pickup_code === numericCode
      );
      if (cachedReservation?.door) {
        await openDoor.trigger(cachedReservation.door.position);
        navigate("code-success");
        return;
      }

      // If no match found, trigger reservation query
      setTriggerReservationQuery(numericCode);
    },
    [
      locker.data?.doors,
      locker.data?.test,
      navigate,
      openDoor,
      rebootSystem,
      reservationCache.data,
      setTriggerReservationQuery,
      totpSecrets,
      validate,
      validateDoorTOTP,
    ]
  );
};
