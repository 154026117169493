import * as OTPAuth from "otpauth";

export const validateTOTP = (
  code: string,
  secrets: TOTPSecret[] | []
): TOTPSecret | null => {
  for (const secret of secrets) {
    const totp = new OTPAuth.TOTP({
      secret: OTPAuth.Secret.fromBase32(secret.secret),
      digits: secret.digits || 8,
      period: secret.period || 180,
      algorithm: "SHA1", // Explicitly set algorithm for consistency
    });

    // Expand window slightly to account for time drift
    if (totp.validate({ token: code, window: 2 }) !== null) {
      return secret;
    }
  }

  return null;
};

export const generateTOTP = (secret: TOTPSecret): string => {
  const totp = new OTPAuth.TOTP({
    secret: OTPAuth.Secret.fromBase32(secret.secret),
    digits: secret.digits || 8,
    period: secret.period || 180,
    algorithm: "SHA1",
  });

  return totp.generate();
};

export const base32Encode = (input: Uint8Array): string => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
  let bits = 0;
  let value = 0;
  let output = "";

  for (let i = 0; i < input.length; i++) {
    value = (value << 8) | input[i];
    bits += 8;
    while (bits >= 5) {
      output += alphabet[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }
  if (bits > 0) {
    output += alphabet[(value << (5 - bits)) & 31];
  }

  // Add padding
  while (output.length % 8 !== 0) {
    output += "=";
  }
  return output;
};

export const createDoorTOTP = async (
  secret: TOTPSecret,
  doorPosition: string
): Promise<OTPAuth.TOTP> => {
  const encoder = new TextEncoder();
  const keyBuffer = await crypto.subtle.importKey(
    "raw",
    encoder.encode(secret.secret),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign(
    "HMAC",
    keyBuffer,
    encoder.encode(doorPosition)
  );

  const base32Secret = base32Encode(new Uint8Array(signature));

  return new OTPAuth.TOTP({
    secret: OTPAuth.Secret.fromBase32(base32Secret),
    digits: 8,
    period: 180,
    algorithm: "SHA1",
  });
};

export const generateDoorTOTP = async (
  secret: TOTPSecret,
  doorPosition: string
): Promise<string> => {
  const totp = await createDoorTOTP(secret, doorPosition);
  return totp.generate();
};

export const validateDoorTOTP = async (
  code: string,
  secret: TOTPSecret,
  doorPosition: string,
  timestamp?: number
): Promise<boolean> => {
  const totp = await createDoorTOTP(secret, doorPosition);
  return totp.validate({ token: code, timestamp, window: 2 }) !== null;
};

export const getRemainingSeconds = (secret: TOTPSecret): number => {
  const period = secret.period || 180;
  return period - (Math.floor(Date.now() / 1000) % period);
};

export const useTOTPValidation = (secrets: TOTPSecret[]) => {
  const validate = (code: string) => {
    return validateTOTP(code, secrets);
  };

  return {
    validate,
    generateTOTP,
    getRemainingSeconds,
    generateDoorTOTP,
    validateDoorTOTP,
  };
};
